import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  PreloadAllModules,
  RouteReuseStrategy,
  Router,
  RouterModule
} from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { AppComponent } from './app.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializer } from './app-init';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainPipe } from './services/pipes/main-pipe.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpInterceptorService } from './services/keycloak/http-interceptor.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ComponentsModule } from './shared/components.module';
import { AppRoutes } from './app.routes';
import { AnalyticsService } from './services/analytics/analytics.service';
import { BrukerService } from './services/bruker/bruker.service';
import { PushNotificationsService } from './services/push-notifications/push-notifications.service';
import { AvtaleService } from './services/avtale/avtale.service';
import { provideMatomo } from 'ngx-matomo-client';
import { withRouter } from 'ngx-matomo-client';
import * as Sentry from '@sentry/capacitor';
import * as SentryAngular from '@sentry/angular-ivy';
import p from 'package.json';

Sentry.init(
  {
    dsn: 'https://da5448662edc89cde238e587cd5c59f4@o4506948978016256.ingest.us.sentry.io/4506948995842048',
    release: 'AppPrivat@' + p.version,
    dist: '1',
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new SentryAngular.BrowserTracing({
        // routingInstrumentation: SentryAngular.routingInstrumentation
      })
    ],
    tracePropagationTargets: ['localhost', /^\//],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production

    // tracesSampleRate: 1.0,
    tracesSampler: (samplingContext) => {
      // Always sample traces in development
      // if (samplingContext.location.hostname === 'localhost') {
      //   return 1;
      // }
      if (samplingContext.transactionContext.name === '/') {
        return 0;
      }
      if (
        samplingContext.transactionContext.name === '/logginn' ||
        samplingContext.transactionContext.name === '/tabs/forbruk' ||
        samplingContext.transactionContext.name === '/broker' ||
        samplingContext.transactionContext.name === '/tabs/'
      ) {
        return 0.2;
      } else {
        return 0.4;
      }
    }
  },
  // Forward the init method from @sentry/angular
  SentryAngular.init
);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ mode: 'md', innerHTMLTemplatesEnabled: true }),
    RouterModule.forRoot(AppRoutes, { preloadingStrategy: PreloadAllModules }),
    KeycloakAngularModule,
    MainPipe,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    ComponentsModule
  ],
  providers: [
    InAppBrowser,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [
        KeycloakService,
        AnalyticsService,
        BrukerService,
        PushNotificationsService,
        AvtaleService,
        SentryAngular.TraceService
      ]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    provideMatomo(
      { trackerUrl: 'https://ishavskraft.matomo.cloud/', siteId: '2' },
      withRouter()
    ),
    {
      provide: ErrorHandler,
      useValue: SentryAngular.createErrorHandler()
    },
    {
      provide: SentryAngular.TraceService,
      deps: [Router]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
