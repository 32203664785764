import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { InfoVarslingPopupComponent } from './info-varsling-popup/info-varsling-popup.component';
import { BarChartComponent } from './charts/bar-chart/bar-chart.component';
import { LineChartComponent } from './charts/line-chart/line-chart.component';
import { AnleggComponent } from './anleggsvelger/anlegg.component';
import { FormsModule } from '@angular/forms';
@NgModule({
  declarations: [
    InfoVarslingPopupComponent,
    BarChartComponent,
    LineChartComponent,
    AnleggComponent
  ],
  exports: [
    InfoVarslingPopupComponent,
    BarChartComponent,
    LineChartComponent,
    AnleggComponent
  ],
  imports: [IonicModule, CommonModule, FormsModule]
})
export class ComponentsModule {}
